/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const UserInputHandler = (props) => {
  const { t } = useTranslation();
  const [userInput, setUserInput] = useState('');
  const [userSelectedAnswer, setSelectedNumber] = useState(0);
  const [inputDisable, setInputDisable] = useState(false);

  const {
    userInputFun,
    position,
    symbolColor,
    symbol,
    diceValue,
    activeInput,
    selectNumber,
    diceClick,
    initialPlayerPST,
  } = props;

  selectNumber ? document.getElementById('inputFocus').focus() : '';

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      userInputFunction();
    }
    if (e.key === 'e' && e.preventDefault()) {
    } else if (e.key === '+' && e.preventDefault()) {
    } else if (e.key === 'E' && e.preventDefault()) {
    }
  };

  const userInputFunction = () => {
    if (userInputFun(userInput)) {
      setUserInput('');
    }
  };

  props.clearNumberColor(userInput);

  const userSelectedNumber = (value) => {
    if (!diceClick && selectNumber > 0) {
      setSelectedNumber(selectNumber);
      setUserInput(userSelectedAnswer);
    } else if (value != undefined) {
      setUserInput(value);
    }
    if (selectNumber > 0 && userInput == selectNumber) {
      userInputFunction();
      setSelectedNumber(0);
    }
  };

  useEffect(() => {
    userSelectedNumber();
    if (window.innerWidth <= 1000) {
      setInputDisable(true);
    } else {
      setInputDisable(activeInput);
    }
  }, [userSelectedNumber]);

  return (
    <>
      <div className="row justify-content-center extramargin text-center userfunc-row d-flex h-100  mt-md-5 mt-lanscape-md-1 dice-container">
        <div className="col-4 col-sm-5 col-md-5  col-lg-3 col-xl-3 custom-xs-padding  justify-content-center ">
          <input
            className="form-control bg-white  align-middle text-center custom-font-size font-weight-bold"
            type="text"
            value={position == initialPlayerPST ? '' : position || ''}
            readOnly
          />
        </div>
        <div
          className="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 custom-xs-padding text-center align-self-center font-weight-bold custom-font-size-1"
          style={{
            color: symbolColor,
          }}
        >
          {symbol}
        </div>
        <div className="col-4 col-sm-5 col-md-5 col-lg-3 col-xl-3 custom-xs-padding">
          <input
            className="form-control bg-white text-center custom-font-size font-weight-bold"
            type="text"
            value={(diceValue ? diceValue : '') || ''}
            readOnly
          />
        </div>
        <div className="col-4 col-sm-4 col-md-4 col-lg-1 col-xl-1  custom-xs-padding font-weight-bold   text-center font-weight-bold custom-font-size-1 custom-margin-userinput">
          =
        </div>
        <div className="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3 custom-xs-padding custom-margin-userinput">
          <input
            id="inputFocus"
            type="number"
            className="form-control text-center font-weight-bold custom-font-size"
            onKeyDown={keyPress}
            onChange={(e) => userSelectedNumber(e.target.value)}
            value={userInput || (diceClick ? ' ' : selectNumber) || ''}
            // id={activeInput && 'disabledFieldsetCheck'}
            disabled={inputDisable}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn common-button-1 custom-font-size"
          onClick={userInputFunction}
        >
          {t('next')}
        </button>
      </div>
    </>
  );
};

export default UserInputHandler;
