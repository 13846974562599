/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const homePageurl = `${process.env.REACT_APP_SITE_URL}/home`;

  return (
    <footer className="bg-dark text-center p-2 main-footer">
      <div className="container">
        <span className="pt-5 text-light footer-font">
          <span className="text-light footer-font">&copy; 2021</span>
          <a href={homePageurl}>
            <b className="text-light footer-font"> {t('foundationName')} </b>
          </a>
          . {t('rightsReserved')}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
