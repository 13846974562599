/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { bounceInRight } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const Dialog = (props) => {
  console.log(props);
  const { t } = useTranslation();
  const bounceAnimation = keyframes`${bounceInRight}`;
  const InstrAnimation = styled.div`
    animation: 1.5s ${bounceAnimation};
  `;

  let instructions;

  if (props.gameType === 'mathboard') {
    instructions = t('mathBoardInstruction', { returnObjects: true });
  }
  if (props.gameType === 'jumpinggame') {
    instructions = t('jumpingNumberInstruction', { returnObjects: true });
  }

  return (
    <>
      {props.gameType !== '' && (
        <div>
          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <InstrAnimation>
                  <div className="modal-body mb-1">
                    <div className="font-size-1 font-weight-bold">
                      {t('instruction')}
                    </div>
                    <div className="container-fluid list">
                      {Object.keys(instructions).map((key, index) => (
                        <li className="math-inst-font-size2 mt-3" key={index}>
                          {t(instructions[key])}
                        </li>
                      ))}
                    </div>
                  </div>
                </InstrAnimation>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dialog;
