/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MathboardGameImg from '../../assets/Video/mathboardGame.png';
import jumpingNumberimg from '../../assets/Video/jumpingGame.png';
import styled from 'styled-components';
import Dialog from '../common/Instructions';

const Container = styled.div`
  padding: 0 4.5rem 0.2rem 5rem;
  @media (max-width: 425px) {
    padding: 0;
  }
`;

const GameCard = () => {
  const [gameType, setGameType] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <section>
      <Container className="container-fluid">
        <div className="row my-5 container-fluid">
          <div className="col-md-12 col-lg-6 mb-5">
            <p
              className="border text-center text-white  mr-4  font-weight-bold mb-4 custom-bg-color"
              style={{
                fontSize: '54px',
                borderRadius: '100%',
                width: '87px',
                float: 'left',
              }}
            >
              01
            </p>
            <div className="font-weight-bold mt-3 h2 math-inst-font-size1">
              {t('mathBoardGame')}
            </div>
            <div>
              <div>
                <img
                  width="100%"
                  height="90%"
                  src={MathboardGameImg}
                  alt="MathBoard Game"
                  className="mathGameImage"
                />
              </div>

              <div className="d-inline">
                <button
                  type="button"
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1 d-block"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => setGameType('mathboard')}
                >
                  {t('instruction')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/math-board',
                      search: '?goback=true&level=one&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('one')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/math-board',
                      search: '?goback=true&level=two&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('two')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/math-board',
                      search: '?goback=true&level=three&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('three')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/math-board',
                      search: '?goback=true&level=four&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('four')}
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6 mb-5">
            <p
              className="border text-center text-white  mr-4  font-weight-bold mb-4 custom-bg-color"
              style={{
                fontSize: '54px',
                borderRadius: '100%',
                width: '87px',
                float: 'left',
              }}
            >
              02
            </p>
            <div className="font-weight-bold mt-3 h2 math-inst-font-size1">
              {t('jumpingNumbers')}
            </div>
            <div>
              <div>
                <img
                  width="100%"
                  height="90%"
                  src={jumpingNumberimg}
                  alt="MathBoard Game"
                  className="mathGameImage"
                />
              </div>

              <div className="d-inline">
                <Dialog gameType={gameType} />
                <button
                  type="button"
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1 d-block"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => setGameType('jumpinggame')}
                >
                  {t('instruction')}
                </button>

                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=one&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('one')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=two&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('two')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=three&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('three')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=four&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('four')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=five&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('five')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=six&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('six')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=seven&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('seven')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=eight&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('eight')}
                </button>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/game/jumping-number',
                      search: '?goback=true&level=nine&timer=stop',
                    });
                  }}
                  className="btn custom-btn mr-3 ml-1 mt-3 mb-2 font-size-1"
                >
                  {t('nine')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default GameCard;
