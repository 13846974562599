/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

const GameHeading = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="container-fluid custom-letter-spacing">
        <div className="row text-center d-flex justify-content-center">
          <div className="col-sm-8 col-md-10 col-lg-8 col-xs-6 col-xl-10 col-xxl-10">
            <div className="text-center">
              <div className="main-page-height">
                <div className="mt-5">
                  <h1 className="card-title gameHeading font-weight-bold">
                    {t('gameHeading')}
                  </h1>
                  <p className="card-text font-weight-bold h3">{t('subHeading')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GameHeading;
