/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import LandingpageImg from '../../assets/img/welcomeHeader.jpg';
import styled, { keyframes } from 'styled-components';
import { bounceInRight, bounceInDown } from 'react-animations';

const Container = styled.div`
  padding: 0 1rem 0 5rem;
  @media (max-width: 425px) {
    padding: 0;
  }
`;
const Section = styled.section`
  border-radius: 0rem !important;
`;
const bounceAnimation = keyframes`${bounceInRight}`;

const InstrAnimation = styled.div`
  animation: 1.8s ${bounceAnimation};
`;

const bounceAnimation2 = keyframes`${bounceInDown}`;

const InstrAnimation2 = styled.div`
  animation: 2.4s ${bounceAnimation2};
`;

const MainPageContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="custom-bg">
      <Container className="container-fluid">
        <div className="row d-flex">
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <InstrAnimation>
              <div className="container mb-4 mt-5 custom-h1 custom-letter-spacing custom-letter-spacing1">
                <span style={{color:'#0D3C6E'}}>{t('mainPageHeading')}</span>
                <span className="animate-charcter" style={{fontWeight:'bold'}}>{t('mainPageHeading0')}</span>
                <span style={{color:'#0D3C6E'}}> {t('mainPageHeading00')}</span>
                <span style={{color:'#B64274',fontWeight:'bold'}}> {t('mainPageHeading1')}</span>
                <span style={{color:'#589ee0',fontWeight:'bold'}}>{t('mainPageHeading2')}</span>
                <span style={{color:'#f2993f',fontWeight:'bold'}}>{t('mainPageHeading3')}</span>
                <span style={{color:'#827717',fontWeight:'bold'}}> {t('mainPageHeading4')}</span>
                <span style={{color:'#5BA79B',fontWeight:'bold'}}>{t('mainPageHeading5')}</span>
                <span style={{color:'#E8A058',fontWeight:'bold'}}>{t('mainPageHeading6')}</span>


              </div>
            </InstrAnimation>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"/>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          {/* <div> */}
            <p>
              <InstrAnimation2>
                <img src={LandingpageImg} width="100%"></img>
              </InstrAnimation2>
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default MainPageContent;
