/* eslint-disable */
import React from 'react'
import Result from '../components/common/Result';

 const Result1 = () => {
    return (
        <section>
        <Result />
      </section>
    )
}

export default Result1;