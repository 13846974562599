/* eslint-disable */
import React from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = (props) => {
  toast[props.type](props.message, {
    position: 'top-left',
  });

  return (
    <div>
      <ToastContainer
        draggable={false}
        transition={Flip}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        del
        pauseOnHover={false}
      />
    </div>
  );
};

export default Toastify;
