/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Timer from '../timer/Timer';
import Dice from './Dice';
import UserInputHandler from './UserInputHandler';

const UserAction = (props) => {
  const { t } = useTranslation();

  const {
    rollDice,
    disableIndividual,
    diceClick,
    userInputFun,
    activeInput,
    position,
    symbol,
    symbolColor,
    diceValue,
    selectNumber,
    initialPlayerPST,
  } = props;

  const clearNumberColor = (userInput) => {
    props.clearNumberColor(userInput);
  };

  return (
    <>
      <div className="row mt-sm-1  mt-md-5 mt-sm-3 mt-3">
        <div className="col">
          <Timer />
        </div>
      </div>

      <div className="row">
        <div className="col text-center die-conatiner mt-md-5 mt-lanscape-lg-1">
          <Dice
            rollDice={rollDice}
            disableIndividual={disableIndividual}
            className=""
          />
        </div>
      </div>

      <div className="row mt-md-5 mt-lanscape-lg-1 dice-container">
        <div className="col text-center">
          <p
            className={`font-weight-bold dice-font-size ${
              diceClick ? 'visible' : 'invisible'
            }`}
          >
            <span className="dice-font-size title-word-1">{t('diceClick1')}</span>
            <span className="dice-font-size title-word-1"> {t('diceClick2')}</span>
            <span className="dice-font-size title-word-1"> {t('diceClick3')}</span>
            <span className="dice-font-size title-word-1"> {t('diceClick4')}</span>
          </p>
        </div>
      </div>

      <div>
        <UserInputHandler
          userInputFun={userInputFun}
          activeInput={activeInput}
          position={position}
          symbol={symbol}
          diceClick={diceClick}
          symbolColor={symbolColor}
          diceValue={diceValue}
          selectNumber={selectNumber}
          clearNumberColor={clearNumberColor}
          initialPlayerPST={initialPlayerPST}
        />
      </div>
    </>
  );
};

export default UserAction;
