/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EachBox from './EachBox';
import styled from 'styled-components';
import mathBoardData from '../../../assets/jsonData/mathBoardData.json';

const Box = styled.div`
  margin: 0.2rem;
  border-radius: 0.5rem;
  width: calc(5.8vw);
  height: calc(8.3vh);
  border: 1px solid black;
  position: relative;
  padding: 0.5rem;
  background: ${(props) => props.background};
  &:hover {
    border: 6px solid green;
    cursor: pointer;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 0.05rem;
    border-radius: 0.5rem;
    width: calc(6.4vw);
    height: calc(9vh);
  }

  @media (min-width: 768px) and (max-width: 875px) and (orientation: landscape) {
    margin: 0.05rem;
    border-radius: 0.5rem;
    width: calc(6.4vw);
    height: calc(9vh);
  }
`;

const BoxRow = styled.div`
  display: flex;
  justify-content: start;
`;

const MathBoardLayout = (props) => {
  const history = useHistory();
  const { level, diceClick, clearColor } = props;
  const [gameLevel, setGameLevel] = useState([]);
  const [currboxIndex, setCurrboxIndex] = useState(0);
  const [currboxNumber, setCurrboxNumber] = useState('');

  useEffect(() => {
    if (level === 'one') {
      setGameLevel(mathBoardData.level1);
      props.initialPlayer(mathBoardData.level1[0].number);
      props.winningNumber(mathBoardData.level1[99].number);
    } else if (level === 'two') {
      setGameLevel(mathBoardData.level2);
      props.initialPlayer(mathBoardData.level2[0].number);
      props.winningNumber(mathBoardData.level2[99].number);
    } else if (level === 'three') {
      setGameLevel(mathBoardData.level3);
      props.initialPlayer(mathBoardData.level3[0].number);
      props.winningNumber(mathBoardData.level3[99].number);
    } else if (level === 'four') {
      setGameLevel(mathBoardData.level4);
      props.initialPlayer(mathBoardData.level4[0].number);
      props.winningNumber(mathBoardData.level4[99].number);
    } else if (level === 'five') {
      setGameLevel(mathBoardData.level5);
      props.initialPlayer(mathBoardData.level5[0].number);
      props.winningNumber(mathBoardData.level5[99].number);
    } else {
      history.push('/home');
    }
  }, []);

  const splitEvery = (arr, size) => {
    let newArr = [];
    for (let i = 0; i < arr.length; i += size) {
      let sliceData = arr.slice(i, i + size);
      newArr.push(sliceData);
    }

    return newArr;
  };

  const singleSymbol = (childData) => {
    props.childsymbol(childData);
  };

  const singleColor = (childColor) => {
    props.childColor(childColor);
  };

  const selectNumber = (ele) => {
    !diceClick && props.selectedNumber(ele);
    !diceClick && setCurrboxNumber(ele);
  };

  const currentboxIndex = (boxIndex) => {
    setCurrboxIndex(boxIndex);
    props.boxIndex(boxIndex);
  };

  const renderBoxes = (props) => {
    const { updatedState } = props;
    const splitArray = splitEvery(gameLevel, 10);
    const reverseArray = splitArray.reverse();

    return reverseArray.map((row, index) => (
      <BoxRow
        className="align-items-center justify-content-center"
        key={index}
        style={{
          flexDirection: index % 2 ? 'row' : 'row-reverse',
        }}
      >
        {row.map((box) => (
          <div onClick={(e) => selectNumber(box.number)} key={box.number}>
            <span>
              <Box
                style={{
                  background:
                    currboxIndex == box.number
                      ? 'green'
                      : clearColor && !diceClick && currboxNumber == box.number
                      ? 'red'
                      : `${box.color}`,
                }}
              >
                <EachBox
                  box={box}
                  boxColor={box.color}
                  boxIndex={box.number}
                  symbol={box.symbol}
                  updatedState={updatedState}
                  numOfPlayers={updatedState.numOfPlayers}
                  particularSymbol={singleSymbol}
                  particularColor={singleColor}
                  currentboxIndex={currentboxIndex}
                ></EachBox>
              </Box>
            </span>
          </div>
        ))}
      </BoxRow>
    ));
  };

  return <div>{renderBoxes(props)}</div>;
};

export default MathBoardLayout;
