/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import UserIcon from '../../../assets/img/userIcon.png';

const Opertors = styled.span`
  font-size: calc(1.5vw + 1.5vh);
  font-weight: 1000;
  position: absolute;
  color: white;
  top: -15%;

  @media (min-width: 0px) and (max-width: 425px) {
    font-size: calc(1.5vw + 1.5vh);
    top: -2%;
  }

  @media (min-width: 426px) and (max-width: 767px) {
    font-size: calc(1.5vw + 1.5vh);
    top: -13%;
  }

  @media (min-width: 768px) and (max-width: 875px) and (orientation: landscape) {
    font-size: calc(1.5vw + 1.5vh);
    top: -21%;
    left: 9%;
  }
`;

const Digits = styled.span`
  font-size: calc(1vw + 1vh);
  font-weight: 800;
  position: absolute;
  right: 10%;
  bottom: 5%;
  color: white;

  @media (min-width: 0px) and (max-width: 767px) {
    font-size: calc(1.5vw + 1.5vh);
    right: 10%;
    bottom: 0%;
  }

  @media (min-width: 768px) and (max-width: 875px) and (orientation: landscape) {
    font-size: calc(1.5vw + 1.5vh);
    right: 10%;
    bottom: 0%;
  }
`;

const UserIconImg = styled.img`
  height: 4vh;
  position: absolute;
  bottom: 12%;
  left: 21%;

  @media (min-width: 768px) and (max-width: 800px) {
    height: 3.2vh;
    bottom: 30%;
    left: 15%;
  }

  @media (min-width: 801px) and (max-width: 854px) {
    height: 5vh;
  }
  @media (min-width: 855px) and (max-width: 1250px) {
    height: 3.2vh;
  }
`;

const EachBox = (props) => {
  const { boxIndex, updatedState, symbol, boxColor } = props;
  console.log(symbol);
  const { numOfPlayers } = updatedState;

  const getPlayerNamesArr = () => {
    let arr = [];

    for (let i = 1; i <= numOfPlayers; i++) {
      arr.push(`P${i}`);
    }

    return arr;
  };

  const getPlayerNames = () => {
    return getPlayerNamesArr().map((playerName) => {
      if (updatedState[playerName].currentPosition === boxIndex) {
        props.particularSymbol(symbol);
        props.particularColor(boxColor);
        props.currentboxIndex(boxIndex);
      }
      if (
        updatedState[playerName].currentPosition == 400 ||
        updatedState[playerName].currentPosition == 0
      ) {
        props.currentboxIndex(0);
      }
    });
  };

  useEffect(() => {
    getPlayerNames();
    return () => getPlayerNames();
  }, [props]);

  const symbolChnage=()=>{
    if (symbol== "+") {
      return <span>&#247;</span>
    }
  }

  return (
    <>
      <Opertors>{symbol == "/" ? <span>&#247;</span> : symbol}</Opertors>
      {getPlayerNamesArr().map(
        (playerName, index) =>
          updatedState[playerName].currentPosition === boxIndex && (
            <div key={index}>
              <UserIconImg src={UserIcon} />
            </div>
          )
      )}
      <Digits>{boxIndex}</Digits>
    </>
  );
};

export default EachBox;
