/* eslint-disable */
import React from 'react';
import MainPageContent from '../components/home/MainPageContent';
import GameHeading from '../components/home/GameHeading';
import GameCard from '../components/home/GameCard';

const Home = () => {
  return (
    <main role="main">
      <MainPageContent />
      <GameHeading />
      <GameCard />
    </main>
  );
};

export default Home;
