/* eslint-disable */
import React, { useState } from 'react';

const AnswerAnimation = (props) => {
  const [classYN, setClassYN] = useState(false);

  const { emojis } = props;

  const changeClass = () => {
    setClassYN(true);
  };

  const generateDrops = () => {
    const drop = document.createElement('div');

    drop.classList.add('custom-drop');
    drop.innerText = emojis[Math.floor(Math.random() * emojis.length)];
    drop.style.left = Math.random() * 62 + 'vw';
    drop.style.animationDuration = Math.random() * 2 + 1 + 's';

    document.body.appendChild(drop);
  };

  const setIntervalGenerateDrops = setInterval(generateDrops, 50);

  const stopInterval = () => {
    clearInterval(setIntervalGenerateDrops);
  };

  setInterval(stopInterval, 2000);

  return (
    <>
      <div className="custom-modal-toggle" onClick={changeClass}></div>
      <div className={'modal emojis-now' + (classYN && 'active')}></div>
    </>
  );
};

export default AnswerAnimation;
