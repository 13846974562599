/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactDice from 'react-dice-complete';
import 'react-dice-complete/dist/react-dice-complete.css';

const Dice = (props) => {
  const [size, setSize] = useState(
    window.matchMedia('(min-width: 1024px)').size
  );

  const rollDoneCallback = (num) => {
    const { rollDice } = props;

    rollDice(num);
    disableIndividual();
  };

  const disableIndividual = (condition) => {
    if ((condition = false)) {
    }
  };

  const diceSize = () => {
    const mql = window.matchMedia('(min-width:1024px)');

    if (mql.matches) {
      setSize(130);
    } else {
      setSize(70);
    }
  };

  useEffect(() => {
    diceSize();

    window.addEventListener('resize', diceSize);
    return () => window.removeEventListener('resize', diceSize);
  }, []);

  return (
    <ReactDice
      numDice={1}
      disableIndividual={props.disableIndividual}
      rollDone={rollDoneCallback}
      dotColor={'#ffffff'}
      faceColor={'#290464'}
      dieSize={size}
      rollTime={2}
    />
  );
};

export default Dice;
