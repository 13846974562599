import React, { Suspense, lazy } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PublicRoute } from './Routes';

// Layouts
import MainLayout from '../layouts/MainLayout';

// Views
import Home from '../views/Home';
import MathBoard from '../views/MathBoard';
import Result1 from '../views/Result1';

const JumpingNumbers = lazy(() => import('../views/JumpingNumbers'));
const Routes = () => {
  return (
    <HashRouter noslash="noslash">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <PublicRoute exact path="/home" component={Home} layout={MainLayout} />
        <PublicRoute
          exact
          path="/game/math-board"
          component={MathBoard}
          layout={MainLayout}
        />

        <Suspense
          fallback={
            <h1
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              <CircularProgress />
            </h1>
          }
        >
          <PublicRoute
            exact
            path="/game/jumping-number"
            component={JumpingNumbers}
            layout={MainLayout}
          />
        </Suspense>
        <PublicRoute
          exact
          path="/common/Result"
          component={Result1}
          layout={MainLayout}
        />

        {/* Default redirect or page url not found */}
        <Redirect to="/home" />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
