import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './history';
import './i18n';
import './assets/css/style.css';
import './assets/css/board.css';
import './assets/css/questions.css';
import './assets/css/answerAnimation.css';
import App from './App';

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);
