/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import GoBack from './GoBack';
import styled from 'styled-components';

const Varitralogo = styled.img`
  position: relative;
  left: 20%;
  @media (max-width: 425px) {
    position: inherit;
  }
`;

const Header = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const search = useLocation().search;
  let level = new URLSearchParams(search).get('level');

  const [popYN, setPopYN] = useState(false);

  const showPopYN = () => {
    if (history.location.pathname == '/home') {
      setPopYN(false);
    } else {
      setPopYN(true);
    }
  };

  useEffect(() => {
    showPopYN();
    return history.listen(() => {
      showPopYN();
    });
  }, []);

  const handleSwitchLanguage = (event) => {
    let selectedLang = event.target.value;
    if (selectedLang === 'English') {
      i18n.changeLanguage('en');
    } else if (selectedLang === 'Hindi') {
      i18n.changeLanguage('hi');
    }
  };

  return (
    <>
      <header>
        <div className="navbar navbar-expand-lg navbar-expand-md navbar-light bg-white border-bottom">
          <div className="container-fluid d-flex justify-content-between">
            {
              /* Button trigger modal */
              popYN ? (
                <a className="navbar-brand" title="Varitra">
                  <Varitralogo
                    onClick={() => {
                      if (level) {
                        history.push({
                          search: `?goback=true&level=${level}&timer=stop`,
                        });
                      } else {
                        history.push({
                          search: '?goback=true&timer=stop',
                        });
                      }
                    }}
                    src={logo}
                    className="img-fluid"
                    width="250px"
                    alt="Varitra Logo"
                    data-toggle="modal"
                    data-target="#modalCenter"
                  />
                </a>
              ) : (
                <a className="navbar-brand" title="Varitra">
                  <Varitralogo
                    onClick={() => {
                      history.push('/home');
                    }}
                    src={logo}
                    className="img-fluid"
                    width="250px"
                    alt="Varitra Logo"
                  />
                </a>
              )
            }
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav mr-auto "></ul>
              <form className="form-inline my-2 my-lg-0">
                {level ? (
                  <div className="btn mr-sm-2 border my-2 my-sm-0 font-size-1">
                    {t(`${level}`)}
                  </div>
                ) : (
                  ' '
                )}

                <select
                  className="custom-select mr-sm-2 font-size-1"
                  id="inputGroupSelect01"
                  onChange={handleSwitchLanguage}
                >
                  <option value="English">English</option>
                  <option value="Hindi">हिंदी</option>
                </select>

                {/* Back button */}
                <GoBack />
              </form>
            </div>
          </div>
        </div>
      </header>

      {/* modal */}
    </>
  );
};

export default Header;
