/* eslint-disable */
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import AlarmOff from '@material-ui/icons/AlarmOff';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
  },
  HomeButoon: {
    backgroundColor: '#1E4752',
    color: 'white',
    marginLeft: '3rem',
    padding: '0.8rem',
    '&:hover': {
      backgroundColor: '#fff',
      padding: '0.7rem',
      color: 'black',
      border: '1px solid #1E4752',
    },
  },

  MoreTime: {
    backgroundColor: '#193d6d',
    color: 'white',
    padding: '0.8rem',
    marginRight: '3rem',
    '&:hover': {
      backgroundColor: '#fff',
      padding: '0.7rem',
      color: 'black',
      border: '1px solid #193d6d',
    },
  },
}));

const TimeReboot = (props) => {
  const { t } = useTranslation();
  const { dialogBox } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMoreTime = () => {
    setOpen(false);
    props.timeRestart();
  };

  useEffect(() => {
    setOpen(dialogBox);
  }, []);

  return (
    <div>
      <Button onClick={handleClickOpen}></Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="mb-1 mt-4 ml-5 mr-5">
          <div className="row d-flex  justify-content-center">
            <div className="col" style={{ fontSize: '3.5rem' }}>
              <AlarmOff style={{ fontSize: '4.5rem', verticalAlign: 'text-bottom'}}/> <span style={{marginLeft:'1.46rem' }}> {t('oops')}</span>   
            </div>
          </div>
          <div className="row d-flex  justify-content-center text-center  ">
            <div className="col mt-2" style={{ fontSize: '2.29rem' }}>
              <span> {t('timeOut')}</span>
              <span> </span>
            </div>
          </div>
        </DialogTitle>
        <DialogActions
          style={{
            paddingBottom: '3rem',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleMoreTime}
            variant="contained"
            className={classes.MoreTime}
            startIcon={<AddAlarmIcon />}
          >
            {t('moreTime')}
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.HomeButoon}
            startIcon={<HomeIcon />}
          >
            {t('homeButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeReboot;
