import React, { Suspense } from 'react';
import Routes from './routing';
import ScrollToTop from './components/common/ScrollToTop';

const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />
      <Routes />
    </Suspense>
  );
};

export default App;
