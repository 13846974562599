/* eslint-disable */
import React from 'react';
import MathBoardGame from '../components/mathBoard/MathBoardGame';

const MathBoard = () => {
  return (
    <section>
      <MathBoardGame />
    </section>
  );
};

export default MathBoard;
