/* eslint-disable */
import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TimeReboot from './TimeReboot';
import FlipTimer from './FlipTimer';

const Timer = () => {
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
 

  const search = useLocation().search;
  let timestop = new URLSearchParams(search).get('timer');

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        if (timestop === 'stop') {
          setSeconds(seconds - 0);
        } else if (timestop === 'start') {
          setSeconds(seconds - 1);
        } else {
          setSeconds(seconds - 1);
        }
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const restartTime = () => {
    setMinutes(15);
    setSeconds(0);
  };

  return (
    <div className="custom-letter-spacing">
      {minutes === 0 && seconds === 0 ? (
        <>
          <TimeReboot dialogBox={true} timeRestart={restartTime} />
          <div className="row justify-content-center timer-font-size2">
            <div>
              <FlipTimer value={`00`} />
            </div>
            <div>:</div>
            <div>
              <FlipTimer value={minutes < 10 ? `0${minutes}` : minutes} />
            </div>
            <div>:</div>
            <FlipTimer value={seconds < 10 ? `0${seconds}` : seconds} />
          </div>
        </>
      ) : (
        <div className="row justify-content-center timer-font-size">
          <div>
            <FlipTimer value={`00`} />
          </div>
          <div>:</div>
          <div>
            <FlipTimer value={minutes < 10 ? `0${minutes}` : minutes} />
          </div>
          <div>:</div>
          <FlipTimer value={seconds < 10 ? `0${seconds}` : seconds} />
        </div>
      )}
    </div>
  );
};

export default Timer;
