/* eslint-disable */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { bounceIn } from 'react-animations';

const GoBack = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const search = useLocation().search;
  const goBack = new URLSearchParams(search).get('goback');
  const level = new URLSearchParams(search).get('level');

  const mainAnimation = keyframes`${bounceIn}`;

  const MainDiv = styled.div`
    animation: 2s ${mainAnimation};
  `;

  const handleClick = () => {
    if (
      history.location.pathname == '/game/math-board' ||
      '/game/jumping-number'
    ) {
      history.push('/home');
    } else {
      history.push('/home');
    }
  };

  return (
    <>
      {goBack && (
        /* Button trigger modal */
        <a
          type="button"
          className="btn custom-btn my-2 my-sm-0 font-size-1"
          onClick={() => {
            if (level) {
              history.push({
                search: `?goback=true&level=${level}&timer=stop`,
              });
            } else {
              history.push({
                search: '?goback=true&timer=stop',
              });
            }
          }}
          data-toggle="modal"
          data-target="#modalCenter"
        >
          {t('back')}
        </a>
      )}
      {/* Modal */}
      <div
        className="modal fade"
        id="modalCenter"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content ">
            <MainDiv>
              <div className="mt-3 modal-header d-flex justify-content-center">
                <p
                  className="custom-font-size p-1 sureMessage"
                  id="exampleModalLongTitle"
                >
                  {t('sureMessage')}
                </p>
              </div>
            </MainDiv>
            <div className="d-flex justify-content-center mb-4">
              <button
                type="button"
                className="btn btn-info sureMessage1 pl-3 pr-3 mr-5"
                onClick={handleClick}
                data-dismiss="modal"
              >
                {t('yes')}
              </button>
              <button
                type="button"
                className="btn btn-primary sureMessage1 ml-3 pl-3 pr-3"
                data-dismiss="modal"
                onClick={() => {
                  if (level) {
                    history.push({
                      search: `?goback=true&level=${level}&timer=start`,
                    });
                  }
                }}
              >
                {t('no')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GoBack;
