/* eslint-disable */
import React from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const MainLayout = ({ children }) => (
  <>
    <Header navPosition="right" className="reveal-from-bottom bg-white" />
    <main className="site-content bg-white">{children}</main>
    <Footer />
  </>
);

export default MainLayout;
