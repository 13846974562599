/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BoardLayout from './board/MathBoardLayout';
import UserAction from './userAction/UserAction';
import Toastify from '../common/Toastify';
import Result from '../common/Result';
import AnswerAnimation from './userAction/AnswerAnimation';

const MathBoardGame = () => {
  const history = useHistory();
  const search = useLocation().search;
  const level = new URLSearchParams(search).get('level');
  const { t } = useTranslation();
  const time = moment().format('hh:mm:ss');

  const [diceCount, setDiceValue] = useState(0);
  const [clikCount, setClickCount] = useState(0);
  const [nextClick, setNextClick] = useState(false);
  const [symbol, setSymbol] = useState('+');
  const [symbolColor, setSymbolColor] = useState('');
  const [diceClick, setDiceClick] = useState(true);
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);
  const [startwatch, setStartWacth] = useState(false);
  const [startedTime, setStartedTime] = useState(false);
  const [result, setResult] = useState(false);
  const [endTime, setEndTime] = useState();
  const [numberIndex, setnumberIndex] = useState(0);
  const [emptyAnswer, setEmptyAnswer] = useState(false);
  const [activeInput, setActiveInput] = useState(1);
  const [clickedNumber, setClickedNumber] = useState(0);
  const [clearColor, setClearColor] = useState('');
  const [initialPlayerPST, setInitialPlayerPST] = useState('');
  const [finalNumber, setFinalNumber] = useState('');

  const initialPlayer = async (position) => {
    await setInitialPlayerPST(position - 1);
  };

  const [playersStateInfo, setNumOfPlayers] = useState({
    showLayout: false,
    numOfPlayers: 1,
    chanceToRollDice: '',
    P1: initialPlayerPST,
  });

  useEffect(() => {
    setStartedTime(time);
    initializeGame();

    if (level) {
      history.push({
        pathname: '/game/math-board',
        search: `?goback=true&level=${level}`,
      });
    } else {
      history.push('/home');
    }
  }, [initialPlayerPST]);

  const initializeGame = () => {
    let P1 = {};

    for (let i = 1; i <= playersStateInfo.numOfPlayers; i++) {
      P1 = {
        currentPosition: initialPlayerPST,
      };
    }

    setNumOfPlayers({
      ...playersStateInfo,
      chanceToRollDice: 'P1',
      showLayout: true,
      P1: P1,
    });
  };

  //timer
  const finishGame = (props) => {
    let endTime = moment().format('hh:mm:ss');

    // calculate total hours
    let hoursDiff = moment(endTime, 'HH:mm:ss').diff(
      moment(startedTime, 'HH:mm:ss'),
      'hours'
    );

    // calculate total minutes
    let minutesDiff = moment(endTime, 'HH:mm:ss').diff(
      moment(startedTime, 'HH:mm:ss'),
      'minutes'
    );

    // calculate total seconds
    let secondsDiff = moment(endTime, 'HH:mm:ss').diff(
      moment(startedTime, 'HH:mm:ss'),
      'seconds'
    );

    // calculate total duration
    let duration = `${hoursDiff}:${minutesDiff}:${secondsDiff}`;
    setEndTime(duration);
  };

  const layoutSymbol = (childData) => {
    setSymbol(childData);
  };

  const winningNumber = (lastNumber) => {
    setFinalNumber(lastNumber);
  };

  const layoutColor = (childColor) => {
    setSymbolColor(childColor);
  };

  const currentboxIndex = (boxnumber) => {
    setnumberIndex(boxnumber);
  };

  const userselectedNumber = (number) => {
    setClickedNumber(number);
  };

  const clearNumberColor = (userInput) => {
    setClearColor(userInput);
  };

  //refresh button controled//
  window.onbeforeunload = function () {
    return '';
  };

  const rollDice = (Dicescore) => {
    setActiveInput(0);
    setNextClick(false);
    setDiceValue(Dicescore);
    setStatus(true);
    setEmptyAnswer(true);
    setTimeout(function () {
      setEmptyAnswer(false);
    }, 1000);
    setDiceClick(false);
    setStartWacth(true);

    if (playersStateInfo.P1.currentPosition === initialPlayerPST) {
      playersStateInfo.P1.currentPosition =
        parseInt(playersStateInfo.P1.currentPosition) + Dicescore;
      setNumOfPlayers({ ...playersStateInfo });
      setDiceValue(0);
      setStatus(false);
      setActiveInput(1);
      setDiceClick(true);
      setClickedNumber(0);
    }
  };

  const userInputFun = (userInput) => {
    setClickCount(clikCount + 1);
    if (userInput === '' || userInput === undefined) {
      if (playersStateInfo.P1.currentPosition === initialPlayerPST) {
        setActiveInput(1);
        setDiceClick(true);
        setClickedNumber(' ');
      } else if (!diceCount) {
        setActiveInput(1);
        setDiceClick(true);
        setClickedNumber(' ');
        setEmptyAnswer(true);
        setTimeout(function () {
          setEmptyAnswer(false);
        }, 2000);
      } else {
        setNextClick(true);
        setError(true);
        setStatus(true);
        setTimeout(function () {
          setError(false);
        }, 2000);
        return true;
      }
    } else if (
      parseInt(updatePlayerPosition(diceCount, true)) === parseInt(userInput)
    ) {
      setActiveInput(1);
      setNextClick(true);
      setDiceClick(true);
      setClickedNumber(' ');
      setStatus(false);
      setAnswer(true);
      setTimeout(function () {
        setAnswer('');
      }, 2000);
      setError(false);
      updatePlayerPosition(diceCount);
      setDiceValue(0);
      return true;
    } else {
      setActiveInput(0);
      setClickedNumber(0);
      setDiceClick(false);
      setNextClick(true);
      setStatus(true);
      setError(false);
      setAnswer(false);
      setTimeout(function () {
        setAnswer('');
      }, 2000);

      return true;
    }
  };

  const updatePlayerPosition = (diceValue, valueRetYN = false) => {
    let P1 = {};

    const currentPlayerPostion = playersStateInfo.P1.currentPosition;

    if (currentPlayerPostion === initialPlayerPST) {
      P1 = {
        currentPosition: playersStateInfo.P1.currentPosition + diceValue,
      };
    }

    const evaluate = (param1, symbol) => {
      switch (symbol) {
        case '+':
          return param1 + diceValue;
        case '-':
          return param1 - diceValue;
        case 'x':
          return param1 * diceValue;
        case '/':
          return param1 / diceValue;
        default:
      }
    };

    if (playersStateInfo.P1.currentPosition < 0) {
      playersStateInfo.P1.currentPosition = 0;
    }

    const data = evaluate(playersStateInfo.P1.currentPosition, symbol);

    if (data == initialPlayerPST) {
      setSymbol('+');
      P1.currentPosition = initialPlayerPST;
    } else if (data == finalNumber) {
      finishGame();
      setResult(true);
    } else if (data < initialPlayerPST) {
      setSymbol('+');
      P1.currentPosition = initialPlayerPST + 1;
    } else if (data > finalNumber) {
      if (symbol == `x`) {
        P1 = {
          currentPosition: numberIndex + 1,
        };
      } else {
        P1 = {
          currentPosition: numberIndex,
        };
      }
    } else {
      P1 = {
        currentPosition: data,
      };
    }

    if (valueRetYN) {
      return data;
    } else {
      setNumOfPlayers({ ...playersStateInfo, P1: P1 });
    }

    if (valueRetYN) {
      return data;
    } else {
      setNumOfPlayers({ ...playersStateInfo, P1: P1 });
    }
  };

  return (
    <>
      {result === true && <Result message={`${t('timeTaken')}: ${endTime}`} />}
      <div className="custom-container">
        <div className="row">
          <div className="pl-board-6 col-md-8 col-sm-8 col-xs-7 col-lg-8 col-xl-8 col-xxl-9 col-8 board-padding align-items-center">
            {playersStateInfo.showLayout && (
              <BoardLayout
                level={level}
                updatedState={playersStateInfo}
                childsymbol={layoutSymbol}
                childColor={layoutColor}
                boxIndex={currentboxIndex}
                selectedNumber={userselectedNumber}
                diceClick={diceClick}
                clearColor={clearColor}
                initialPlayer={initialPlayer}
                winningNumber={winningNumber}
              />
            )}
          </div>

          <div
            className="pl-0 col-md-4 col-sm-4 col-xs-5 col-lg-4 col-xl-4 col-xxl-3 col-4"
            style={{ backgroundColor: '#D1E8B1' }}
          >
            <UserAction
              rollDice={rollDice}
              disableIndividual={status}
              diceClick={diceClick}
              userInputFun={userInputFun}
              activeInput={activeInput}
              position={playersStateInfo.P1.currentPosition}
              symbol={symbol}
              symbolColor={symbolColor}
              diceValue={diceCount}
              selectNumber={clickedNumber}
              clearNumberColor={clearNumberColor}
              initialPlayerPST={initialPlayerPST}
            />
            {nextClick &&
              (((error || emptyAnswer) && (
                <Toastify type={'error'} message={t('enterAnswer')} />
              )) ||
                (answer && (
                  <AnswerAnimation emojis={['😊', '✨', '👍', '✨']} />
                )) ||
                (answer !== '' && !answer && (
                  <AnswerAnimation emojis={['😔', '😟', '😢', '👎']} />
                )))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MathBoardGame;
