/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import styled, { keyframes } from 'styled-components';
import './starRating.css';
import congracts from '../../assets/img/congracts.png';
import betterLuckNextTime from '../../assets/img/betterLuckNextTime.png';
import { useTranslation } from 'react-i18next';
import {
  bounceInLeft,
  flash,
  fadeInLeftBig,
  bounceIn,
  slideInLeft,
  slideInRight,
} from 'react-animations';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  HomeButoon: {
    backgroundColor: '#1E4752',
    color: 'white',
    marginLeft: '2rem',
    padding: '0.8rem',
    '&:hover': {
      padding: '0.7rem',
      color: 'black',
      border: '1px solid #1E4752',
      backgroundColor: '#F4F8F8',
    },
  },
  PlayAgain: {
    backgroundColor: '#193d6d',
    color: 'white',
    padding: '0.8rem',
    '&:hover': {
      padding: '0.7rem',
      color: 'black',
      border: '1px solid #193d6d',
      backgroundColor: '#F4F8F8',
    },
  },
}));

const mainAnimation = keyframes`${bounceIn}`;

const MainDiv = styled.div`
  animation: 2s ${mainAnimation};
`;

const resultAnimation = keyframes`${bounceInLeft}`;

const ResultBounce = styled.div`
  animation: 2s ${resultAnimation};
`;

const animation = keyframes`${flash}`;

const CongratsBounce = styled.div`
  animation: 6s ${animation};
  height:{50%};
  width:{50%}
`;

const leftMessageAnimation = keyframes`${fadeInLeftBig}`;

const Message = styled.div`
  animation: 3s ${leftMessageAnimation};
`;

const leftAnimation = keyframes`${slideInLeft}`;

const LeftButton = styled.div`
  animation: 3s ${leftAnimation};
`;

const rightanimation = keyframes`${slideInRight}`;

const RightButton = styled.div`
  animation: 3s ${rightanimation};
`;

const Result = (props) => {
  const history = useHistory();
  const search = useLocation().search;
  const level = new URLSearchParams(search).get('level');

  const classes = useStyles();

  const { t } = useTranslation();

  const { message, score } = props;
  const [open, setOpen] = useState(true);
  const [confetti, setConfetti] = useState(true);

  setTimeout(() => {
    setConfetti(false);
  }, 5000);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    history.push({
      search: `?goback=true&timer=stop&level=${level}`,
    });
  }, []);

  const handleAction = (data) => {
    setOpen(false);

    if (data == 'playAgain') {
      window.onbeforeunload = function () {
        window.onbeforeunload = false;
      };

      window.location.reload();
    } else {
      history.push('/home');
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
        {confetti && score > 5 && <Confetti className="confetti" />}

        <div className="result-maindiv">
          <DialogTitle className=" result-popup">
            <MainDiv>
              <div className="d-flex  justify-content-center result-popup">
                {score > 5 ? (
                  <img src={congracts} width="100%" height="auto"></img>
                ) : (
                  <img
                    src={betterLuckNextTime}
                    width="100%"
                    height="auto"
                  ></img>
                )}
              </div>{' '}
            </MainDiv>
          </DialogTitle>

          <DialogContent>
            <Message>
              <DialogContentText
                style={{
                  fontFamily: 'Permanent Marker',
                  fontSize: '3vw',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  color: 'black',
                }}
              >
                {message}
              </DialogContentText>
            </Message>
          </DialogContent>

          <DialogActions
            style={{
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'between',
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columns={{ xs: 12, md: 8 }}
              justifyContent="center"
            >
              <Grid item></Grid>
              <Grid item>
                <Button
                  onClick={() => handleAction('playAgain')}
                  variant="contained"
                  className={classes.PlayAgain}
                  startIcon={<RefreshIcon />}
                >
                  {t('playAgain')}
                </Button>

                <Button
                  onClick={handleAction}
                  variant="contained"
                  className={classes.HomeButoon}
                  startIcon={<HomeIcon />}
                >
                  {t('homeButton')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default Result;
